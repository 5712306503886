import '../App.css';
import '../aboutus.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import CustomCol from './CustomCol';

function AboutUs() {

    useEffect(() => {
        // 在頁面渲染完成後，將滾動位置設置為最上方
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div style={{ height: '60px', backgroundColor: 'rgba(0, 0, 0, 0)' }}></div>
            <Container>
                <Row style={{ margin: '28px 0 22px 0' }}>
                    <Col md={4}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end', // 将内容垂直对齐到底部
                                height: '100%', // 设置容器高度，确保内容在可视区域内
                            }}
                        >
                            <div>
                                <h2 className="english_FontFamily2" style={{ fontWeight: '750' }}>We believe in</h2>
                                <h2 className="english_FontFamily2" style={{ fontWeight: '750' }}>human connections</h2>
                            </div>
                        </div>
                    </Col>
                    <Col md={{ span: 5, offset: 3 }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center', // 将内容水平居中
                                alignItems: 'flex-end', // 将内容垂直对齐到底部
                                height: '100%', // 设置容器高度，确保内容在可视区域内
                            }}
                        >
                            <div>
                                <h6 className="english_FontFamily2" >Our mission is to make it safer for</h6>
                                <h6 className="english_FontFamily2" >organisations to interact and work together.</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <Container>
                <Row style={{ margin: '24px 0' }}>
                    <Col className='px-0' md={3}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/aboutus/about01.jpg)`,
                            backgroundSize: 'cover',
                        }}
                    >
                        {/*<img src='/aboutus/about01.jpg' alt='aboutus' style={{ width: '100%' }} />
                    </Col>
                    <Col className="px-4" md={6}>
                        <div className='px-4'>
                            <a href='https://advancharis.com/Page/Home/Index.aspx' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}></a>
                            <div className="p-3" style={{ border: '1px solid #B8B8B8' }}>
                                <div className="image-container">
                                    <div className='left-content'>
                                        <h5 className="english_FontFamily2" style={{ fontSize: '16px', fontWeight: '800' }}>Advancharis</h5>
                                        <h5 style={{ fontFamily: 'Noto Sans TC Bold' }}>恩典科研</h5>
                                    </div>
                                    <div className='center-content'>
                                        <img src="/global/plus.png" alt="Center Image" style={{ width: '24px' }} />
                                    </div>
                                    <div className='right-content'>
                                        <img src="/aboutus/about03.png" alt="Right Image" style={{ width: '120px' }} />
                                    </div>
                                </div>
                                <p className='mt-3 mb-2' style={{ fontSize: '12px', fontFamily: 'Noto Sans TC Regular' }}>恩典科研為國際知名廠牌代理，代理來自 Eckert & Ziegler 的產品。主要在於核醫診斷治療藥物生產相關設備及原料供應，還有提供研究用動物型分子斷層掃描設備，材料及工業用奈米級斷層掃描設備銷售及掃描服務。就核醫診斷治療藥物，提供客戶整體規劃於核醫專業標靶治療之場所設置，設備，材料供應到最終使用之全套服務。就研究用分子影像設備，提供國際最優設備給國內各研究及學術單位，公司成立至今，不受環境影響，始終是正向持續成長，收益穩定。</p>
                            </div>

                            <a href=' https://www.acdt.com.tw/zh-tw/' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}></a>
                            <div className="p-3" style={{ border: '1px solid #B8B8B8' }}>
                                <div className="image-container">
                                    <div className='left-content'>
                                        <img src="/aboutus/about04.png" alt="Right Image" style={{ width: '220px' }} />
                                    </div>
                                    <div className='center-content'>
                                        <img src="/global/plus.png" alt="Center Image" style={{ width: '24px' }} />
                                    </div>
                                    <div className='right-content'>
                                        <h5 className="english_FontFamily2" style={{ fontSize: '16px', fontWeight: '800' }}>ACDT</h5>
                                        <h5 style={{ fontFamily: 'Noto Sans TC Bold' }}>廣隆欣業</h5>
                                    </div>
                                </div>
                                <p className='mt-3 mb-2' style={{ fontSize: '12px', fontFamily: 'Noto Sans TC Regular' }}>廣隆欣業因應市場需求，成立專業無塵室設備製造廠，以專業設計能力及優質製造工藝，為國內外廣大高科技產業提供高品質及特殊設備的製造服務。擁有數十年專業經驗以及優秀的研發團隊，提供不同產業客戶在無塵無菌設備建造上的解決方案，潔淨室相關經驗從Class 1 - 100,000 皆有實際之豐富實績。多年來努力不懈，不斷追求突破、研究創新，客戶群已遍及全世界60多個國家，服務超過 4,000 家的合作客戶。於全球擁有眾多專利並取得UL、CE、ISO 9001、ISO 13485、ISO 14001等多達36項認證</p>
                            </div>

                        </div>
                    </Col>
                    <Col md={3} className='px-0'
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/aboutus/about02.jpg)`,
                            backgroundSize: 'cover',
                        }}
                    >
                        {/* <img src='/aboutus/about02.jpg' alt='aboutus' style={{ width: '100%' }} />
                    </Col>
                </Row>
            </Container>*/}
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/aboutus/aboutus2.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"></div>
                <div className="video-overlay-text3">
                    <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}>專業國際代理</h4>
                    <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}>專注品質，用心服務</h4>
                    <br />
                    <p>近年來，藉由空氣傳染的呼吸道傳染病週期性的在全球引起許多健康危機。2020 Covid-19 疫情的爆發，更是讓空氣品質的議題一躍成為每個人所關心的健康話題，尤其是這個世代，我們有超過三分之一的時間都在於室內活動與人交流，如何有效提升我們生活空間的空氣品質，有效降低染疫的風險提升生活的安全性，儼然成為一個評估生活品質的重要指標。</p>
                </div>
                <div className="video-overlay-text2">
                    <Link to="/contact"><button className="transparent-button">立即訂購</button></Link>
                    {/*<img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />*/}
                </div>
            </div>
            <div style={{ padding: '0 24px' }}>
                <Container >
                    <Row style={{ margin: "70px 0 50px 0" }}>
                        <Col
                            md={4}
                            style={{ paddingRight: '24px' }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/aboutus/about01.jpg)`,
                                    backgroundSize: 'cover',
                                }}
                            />
                            {/** <img src="/aboutus/about01.jpg" alt="" style={{ width: '100%' }} />*/}
                        </Col>
                        <div className="image-company">
                            <br />
                            <img src="/aboutus/about01.jpg" />
                            <br />
                        </div>
                        <Col md={8}>
                            <a href='https://advancharis.com/Page/Home/Index.aspx' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="p-3" style={{ border: '1px solid #B8B8B8' }}>
                                    <div className="image-container">
                                        <div className='left-content'>
                                            <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>總代理經銷</h6>
                                            <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>恩典科研 <span className="english_FontFamily2" style={{ fontSize: '16px', fontWeight: '800' }}>Advancharis</span></h6>
                                        </div>
                                        <div className='right-content'>
                                            <img className="aboutUsImg" src="/aboutus/about03.png" alt="Right Image" style={{}} />
                                        </div>
                                    </div>
                                    <div style={{ margin: '24px 0 12px 0' }}>
                                        <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />公司簡介</h6>
                                        <p className='mb-4' style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>國際知名廠牌代理，主要在於核醫診斷治療藥物生產相關設備及原料供應，還有提供研究用動物型分子斷層掃描設備，材料及工業用奈米級斷層掃描設備銷售及掃描服務。就核醫診斷治療藥物，公司提供客戶整體規劃於核醫專業標靶治療之場所設置，設備，材料供應到最終使用之全套服務。就研究用分子影像設備，提供國際最優設備給國內各研究及學術單位，公司成立至今，不受環境影響，始終是正向持續成長，收益穩定。</p>
                                        <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />產品與服務</h6>
                                        <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>+ 代理產品 Bruker PET SPECT CT 相關研究用影像斷層掃描設備</p>
                                        <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>+ Eckert & Ziegler 藥物藥物合成設備，品管分析設備，Ga-68 Lu-177 放射性同位素</p>
                                        <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>+ Isotopia Lu-177 放射性同位素及診斷試劑</p>
                                        <p className='mb-4' style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>+ TEMA Sinergie 藥物藥物熱核室及藥品分裝設備</p>
                                        <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />實驗室服務</h6>
                                        <p className='mb-4' style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>離體顯微斷層掃描服務，適用於電子、材料、化石、紡織、寶石、複合材料、醫材、骨頭、食品、昆蟲、植物、海洋生物、機械等等，運用相當廣泛。採集180度至360度之二維投射的影像資訊，然後組合成三維的立體影像，超高解析度的設計，最高可達100nm。掃描可依解析度需求調整放大倍率或掃描範圍。掃描服務之條件及樣品處理均依個案另行討論。掃描服務之收費依掃描時間、分析時間、耗材議定。</p>
                                        <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />特殊潔淨與安全場所</h6>
                                        <p style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>工程潔淨實驗室，PIC/S 藥廠規劃，設計，施工，BSL/ABSL 實驗室，輻射防護工程</p>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    </Row>
                    <Row style={{ margin: "0 0 150px 0" }}>
                        <div className="image-company">
                            <br />
                            <img src="/aboutus/about02.jpg" />
                            <br />
                        </div>
                        <Col md={8}>
                            <a href=' https://www.acdt.com.tw/zh-tw/' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="p-3" style={{ border: '1px solid #B8B8B8' }}>
                                    <div className="image-container">
                                        <div className='left-content'>
                                            <img className="aboutUsImg" src="/aboutus/about04.png" alt="Right Image" style={{}} />
                                        </div>
                                        <div className='right-content'>
                                            <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>設備研發製造</h6>
                                            <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>廣隆欣業 <span className="english_FontFamily2" style={{ fontSize: '16px', fontWeight: '800' }}>ACDT</span></h6>
                                        </div>
                                    </div>
                                    <div style={{ margin: '24px 0 12px 0' }}>
                                        <div className="mb-4" >
                                            <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />關於我們</h6>
                                            <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>專業無塵室設備製造商</p>
                                            <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular' }}>廣隆欣業因應市場需求，成立專業無塵室設備製造廠，以專業設計能力及優質製造工藝，為國內外廣大高科技產業提供高品質及特殊設備的製造服務。潔淨室相關經驗從Class 1 - 100,000 皆有實際之豐富實績。</p>
                                        </div>
                                        <div className='mb-4'>
                                            <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />經營理念</h6>
                                            <img src="/aboutus/about08.png" alt="" style={{ width: '100%' }} />
                                        </div>
                                        <h6 className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}><img src="/global/plus.png" style={{ width: '20px', marginRight: '2px' }} />廣隆精神</h6>
                                        <p className="mb-1" style={{ fontSize: '13px', fontFamily: 'Noto Sans TC Regular', paddingBottom: '8px' }}>多年來我們努力不懈，不斷追求突破、研究創新，客戶群已遍及全世界60多個國家，服務超過 4,000 家的合作客戶。於全球擁有眾多專利並取得UL、CE、ISO 9001、ISO 13485、ISO 14001等多達36項認證。</p>
                                        <img src="/aboutus/about07.png" alt="" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </a>
                        </Col>
                        <Col
                            md={4}
                            style={{ paddingLeft: '24px' }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/aboutus/about02.jpg)`,
                                    backgroundSize: 'cover',
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/aboutus/MVI_9949.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"  style={{ backdropFilter: 'blur(5px)' }}></div>
        
                <div className="video-overlay-text2fix">
                    <div className="video-overlay-text2fixfix">
                        <img src='/aboutus/certification03.png' alt='' />
                        <br />
                        <br />
                        <img src='/aboutus/certification01.png' alt='' />
                        {/*<img src='/global/learnmore.png' alt='了解更多' style={{ width: '300px', height: 'auto' }} />*/}
                    </div>
                </div>

            </div>
            {/*<Container style={{ padding: '150px 0' }}>
                <Row>
                    <Col md={12}>
                        <img src='/aboutus/about05.jpg' alt='' style={{ width: '100%' }} />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" md={3}>
                        <p className='m-0'>CHIEF EXECUTIVE OFFICER</p>
                        <h5 className='my-3'>Mandy Huang</h5>
                        <p className='m-0'>Vice President IT&TV Product</p>
                    </Col>
                    <Col className="text-center" md={3}>
                        <p className='m-0'>CHIEF EXECUTIVE OFFICER</p>
                        <h5 className='my-3'>Mandy Huang</h5>
                        <p className='m-0'>Vice President IT&TV Product</p>
                    </Col>
                    <Col className="text-center" md={3}>
                        <p className='m-0'>CHIEF EXECUTIVE OFFICER</p>
                        <h5 className='my-3'>Mandy Huang</h5>
                        <p className='m-0'>Vice President IT&TV Product</p>
                    </Col>
                    <Col className="text-center" md={3}>
                        <p className='m-0'>CHIEF EXECUTIVE OFFICER</p>
                        <h5 className='my-3'>Mandy Huang</h5>
                        <p className='m-0'>Vice President IT&TV Product</p>
                    </Col>
                </Row>
            </Container>*/}
        </div>
    )
}

export default AboutUs;