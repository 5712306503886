import '../App.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import ProductCarousel from './ProductCarousel';
import React, { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';


function Contact() {
    const form = useRef();
    const form2 = useRef();

    useEffect(() => {
        // 在頁面渲染完成後，將滾動位置設置為最上方
        window.scrollTo(0, 0);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_5ewwi12', 'template_wgop4xf', form.current, 'tlOX4DJo3Mk6pxa3o')
            .then((result) => {
                console.log("success");
                alert("謝謝您的來信，本公司將儘速與您聯繫。");
                window.location.href = "/contact";
            }, (error) => {
                console.log(error.text);
            });
    };

    const sendEmail2 = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_5ewwi12', 'template_wgop4xf', form2.current, 'tlOX4DJo3Mk6pxa3o')
            .then((result) => {
                console.log("success");
                alert("謝謝您的來信，本公司將儘速與您聯繫。");
                window.location.href = "/contact";
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div>
            <div style={{ height: '60px', backgroundColor: 'rgba(0, 0, 0, 0)' }}></div>
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/contact/street.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay" style={{ backdropFilter: 'blur(8px)' }}></div>
                <div className="video-overlay-text1">
                    <h3 className="contacttext" style={{ fontFamily: 'Noto Sans TC Bold', marginTop: '200px' }}>如有任何採購需求或好奇疑問，歡迎加入官方 LINE@，或直接填寫下方表單，與我們聯繫，將由專人為您服務！</h3>
                </div> 
            </div>
            <Container className="Contact2">
                <Row>
                    <Col md={4} className="Contact1">
                        <ProductCarousel />
                    </Col>
                    <Col md={8}>
                        <Form className="webForm" ref={form2} onSubmit={sendEmail2}>
                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control className="p-2" type="text" name="user_name" placeholder="姓名" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control className="p-2" type="text" name="user_company" placeholder="公司" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control className="p-2" type="text" name="user_phone" placeholder="連絡電話" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control className="p-2" type="email" name="user_email" placeholder="電子信箱" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-4">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control className="p-2" type="text" as="textarea" name="user_message" rows={4} placeholder="詳細需求" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                                </Form.Group>
                            </Row>

                            <div className="d-flex justify-content-end">
                                <Button className="submitBtn" variant="secondary" type="submit" style={{ padding: '14px 0', width: '240px', border: 'none', fontFamily: 'Noto Sans TC Regular' }}>提交</Button>
                            </div>
                        </Form>

                        <Form className="mobileForm" style={{ padding: "0 16px" }} ref={form} onSubmit={sendEmail}>
                            <Form.Control className="p-2 mb-3" type="text" name="user_name" placeholder="姓名" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                            <Form.Control className="p-2 mb-3" type="text" name="user_phone" placeholder="連絡電話" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                            <Form.Control className="p-2 mb-3" type="text" name="user_company" placeholder="公司" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                            <Form.Control className="p-2 mb-3" type="email" name="user_email" placeholder="電子信箱" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                            <Form.Control className="p-2 mb-3" type="text" as="textarea" name="user_message" rows={4} placeholder="詳細需求" style={{ backgroundColor: 'transparent', border: '1px solid #808080', fontFamily: 'Noto Sans TC Regular' }} />
                            <Button className="submitBtn" variant="secondary" type="submit" style={{ padding: '14px 0', width: '100%',  border: 'none', fontFamily: 'Noto Sans TC Regular' }}>提交</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container className='Home1' fluid="md">
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px', textAlign: "center" }} /></div>
                <Row>
                    <Col xs={12} sm={6}>
                        <img src='/mainpage/dm01.jpg' alt='dm1' style={{ width: '100%' }} />
                        <br />
                    </Col>
                    <Col xs={12} sm={6}>
                        <img src='/mainpage/dm02.jpg' alt='dm2' style={{ width: '100%' }} />
                        <br />
                    </Col>
                </Row>
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px', textAlign: "center" }} /></div>
                <br />
            </Container>
        </div>
    )
}

export default Contact;