import Carousel from 'react-bootstrap/Carousel';

const ProductCarousel = () => {
    return (
        <Carousel className='ProductCarousel1'>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/contact/contact04.png"
                    alt="First slide"
                />
                <div style={{ position: 'relative', top: '20px', textAlign: 'center', }}>
                    {/*<p>NTD 999,999</p>*/}
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/contact/contact05.png"
                    alt="First slide"
                />
                <div style={{ position: 'relative', top: '20px', textAlign: 'center' }}>
                    {/*<p>NTD 999,999</p>*/}
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/contact/contact06.png"
                    alt="First slide"
                />
                <div style={{ position: 'relative', top: '20px', textAlign: 'center' }}>
                    {/*<p>NTD 999,999</p>*/}
                </div>
            </Carousel.Item>
        </Carousel>
    );
};

export default ProductCarousel;