import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts.css';
import './App.css';
import './global.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { BsList, BsXLg, BsXCircleFill } from "react-icons/bs";
import Home from './components/Home';
import Product from './components/Product';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';

function App() {

  const [scrollPosition, setScrollPosition] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const toggleMobileNav = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const topNavHeight = 60; // 顶部导航栏的高度，需要与 CSS 中的设置保持一致
  const extraBackgroundHeight = scrollPosition - topNavHeight > 60 ? 60 : Math.max(scrollPosition - topNavHeight, 0);

  return (
    <Router>
      <div className="App">
        <Link to="/"><img className='app-logo' src='/global/Logo.png' alt='space air+' style={{ width: '120px', height: 'auto' }} /></Link>
        <div className="header">
          <nav className="top-nav">
            <div className="nav-text text-shadow">
              <Link to="aboutus">關於我們</Link>
              <Link to="product">產品介紹</Link>
              <Link to="contact">客服洽詢</Link>
            </div>
          </nav>
        </div>
        <div className="mobile_header">
          <div className="top-nav">
            <BsList className='fixed-Icon' onClick={toggleMobileNav} />
          </div>
          <div className={`right-drawer ${isOpen ? 'open' : ''}`} style={{ zIndex: 1000000 }}>
            {/** <div style={{ display: "flex", alignItems: "center", width: "100%", padding: "16px" }}>
              <div style={{ flex: 1, textAlign: "center" }}>
                <Link to="/" onClick={toggleMobileNav}><img src='/global/Logo.png' style={{ width: '140px', height: "auto" }} /></Link>
              </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <img src='/global/plus.png' onClick={toggleMobileNav} style={{ width: '24px', height: "auto" }} />
              </div>
            </div>*/}
            <BsXCircleFill onClick={toggleMobileNav} style={{ width: '28px', height: "auto", position: "absolute", top: "28px", right: "20px", color: '#FCB208' }} />
            <div style={{ width: "100%", textAlign: "center", padding: "12px 0" }}>
              <Link to="/" onClick={toggleMobileNav}>
                <img src='/global/Logo2.png' style={{ width: '140px', height: "auto" }} />
              </Link>
            </div>
            <div style={{ width: "100%", textAlign: "center", padding: "12px 0" }}><Link to="aboutus" style={{ textDecoration: 'none', color: "#757679" }} onClick={toggleMobileNav}>關於我們</Link></div>
            <div style={{ width: "100%", textAlign: "center", padding: "12px 0" }}><Link to="product" style={{ textDecoration: 'none', color: "#757679" }} onClick={toggleMobileNav}>產品介紹</Link></div>
            <div style={{ width: "100%", textAlign: "center", padding: "12px 0" }}><Link to="contact" style={{ textDecoration: 'none', color: "#757679" }} onClick={toggleMobileNav}>客服洽詢</Link></div>
          </div>
        </div>
        <div
          className="extra-background"
          style={{ height: `${extraBackgroundHeight}px` }}
        ></div>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/product" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <a href="https://lin.ee/RdRuxUJ" target="_blank"><img src="/global/line.png" style={{ position: "fixed", bottom: "24px", right: "24px", width: "72px", height: "auto", zIndex: "999" }} /></a>
        <div className="footer">
          <div className="nav-text1">
            {/** <a href="#news"><p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular' }}>客服專線</p></a>*/}
            <a href="https://lin.ee/RdRuxUJ" target="_blank"><p style={{ paddingTop: '15px', paddingBottom: '15px', fontFamily: 'Noto Sans TC Regular', fontSize: '20px' }}>點我加入官方 LINE@ 專人服務</p></a>
            {/** <a href="#about"><p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular' }}>關注我們</p></a>*/}
          </div>
          <div style={{ marginBottom: '15px', textAlign: "center" }}>
            <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>【製造商】</p>
            <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>廣隆欣業股份有限公司二廠</p>
            <p style={{ marginBottom: '25px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>台中市霧峰區四德路280巷25號</p>
            <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>【總代理】</p>
            <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>恩典科研股份有限公司</p>
            <p style={{ marginBottom: '25px', fontFamily: 'Noto Sans TC Regular', color: 'white' }}>新北市中和區中山路二段332巷31號11樓</p>
            <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white', fontWeight: 'bold' }}>【廣告許可證字號】</p>
            <p style={{ marginBottom: '40px', fontFamily: 'Noto Sans TC Regular', color: 'white', fontWeight: 'bold' }}>中市衛器廣字第11305027號</p>
          </div>
          <hr />
          <p className="nav-text" style={{ fontFamily: 'Noto Sans TC Regular', color: '#757679' }}>2024 spaceair+ All Rights Reserved</p>
        </div>
        {/** 
        // <div className="headbottom">
        //   <div className="totext"> 
        //     本網站仍在開發測試中 
        //   </div>
        // </div> 
        */}
      </div>
    </Router>
  );
}

export default App;
