// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  display: flex;
  align-items: center; /* 將內容垂直置中 */
  width: 100%;
}

.left-content,
.right-content {
  flex: 1 1;
}

.right-content {
    text-align: right;
}

.center-content {
  flex-basis: 10%;
}

.aboutUsImg {
  width: 200px;
}

@media screen and (max-width: 768px) {
  .aboutUsImg {
    width: 140px;
  }
}`, "",{"version":3,"sources":["webpack://./src/aboutus.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,YAAY;EACjC,WAAW;AACb;;AAEA;;EAEE,SAAO;AACT;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".image-container {\n  display: flex;\n  align-items: center; /* 將內容垂直置中 */\n  width: 100%;\n}\n\n.left-content,\n.right-content {\n  flex: 1;\n}\n\n.right-content {\n    text-align: right;\n}\n\n.center-content {\n  flex-basis: 10%;\n}\n\n.aboutUsImg {\n  width: 200px;\n}\n\n@media screen and (max-width: 768px) {\n  .aboutUsImg {\n    width: 140px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
