import React from 'react';
import { Col } from 'react-bootstrap';
import GRID_COLUMNS from './gridConfig';

const CustomCol = (props) => {
    const { xs, sm, md, lg, xl, ...otherProps } = props;

    // 计算新的col大小
    const newColSizes = {
        xs: Math.floor((GRID_COLUMNS / 2) * xs),
        sm: Math.floor((GRID_COLUMNS / 2) * sm),
        md: Math.floor((GRID_COLUMNS / 2) * md),
        lg: Math.floor((GRID_COLUMNS / 2) * lg),
        xl: Math.floor((GRID_COLUMNS / 2) * xl),
    };

    return <Col {...newColSizes} {...otherProps} />;
};

export default CustomCol;