import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function Product() {

    const [value, setValue] = useState(0);
    const targetElementRef = useRef(null); // 创建一个ref来引用目标元素
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    // 创建IntersectionObserver对象，用于监听目标元素是否在视口中可见
    useEffect(() => {
        const options = {
            root: null, // 表示视口作为根元素
            rootMargin: '0px',
            threshold: 0, // 0表示目标元素可见时就触发回调
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !isAnimationStarted) {
                    setIsAnimationStarted(true);
                    animateValue(0, 99.9, 3000); // 从0增加到100的动画，持续时间为1000毫秒
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        if (targetElementRef.current) {
            observer.observe(targetElementRef.current);
        }

        return () => {
            if (targetElementRef.current) {
                observer.unobserve(targetElementRef.current);
            }
        };
    }, [isAnimationStarted]);

    // 动画函数，从startValue增加到endValue，持续duration毫秒
    const animateValue = (startValue, endValue, duration) => {
        let start;
        const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            setValue(startValue + (endValue - startValue) * (progress / duration));
            if (progress < duration) {
                requestAnimationFrame(step);
            } else {
                setValue(endValue);
            }
        };
        requestAnimationFrame(step);
    };


    useEffect(() => {
        // 在頁面渲染完成後，將滾動位置設置為最上方
        window.scrollTo(0, 0);
    }, []);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);


    return (
        <div>
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/product/demo.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"></div>
                <div className="video-overlay-text1">
                    <img src='/global/spaceair.png' alt='space air+' style={{ width: '380px', height: 'auto' }} />
                </div>
                <div className="video-overlay-text1">
                    <h3 style={{ fontFamily: 'Noto Sans TC Medium', marginTop: '200px' }}>醫用空氣淨化機</h3>
                </div>
                <div className="video-overlay-text2">
                    <div>
                        <Link to="/contact" className='mx-1'><button className="transparent-button4">立即訂購</button></Link>
                        <button className="transparent-button4 mx-1" onClick={handleShow}>產品說明</button>
                        {/* <img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />
                        <img src='/product/introVideo.png' alt='介紹影片' style={{ width: '300px', height: 'auto' }} />*/}
                    </div>
                </div>
                <Modal show={modalShow} onHide={handleClose} centered size="lg" dialogClassName="modal-90w" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '30px' }}>
                        <div>
                            <img src='/product/product11.png' style={{ width: "100%", marginBottom: '12px' }} />
                            <h5 style={{ marginBottom: '18px', fontFamily: 'Noto Sans TC Medium' }}><b>CACis – 8D</b></h5>
                            <p className='mb-0'>參考【正壓防護室-骨隨移植病房】之理念設計</p>
                            <p> 提供單一流向之水平氣流，降低醫生看診時感染風險，也能提升室內之空氣品質。</p>
                            <p>骨隨移植病房屬於正壓防護室，主要提供因血液性疾病而造成免疫力極度下降的患者使用，因其病房需極高的潔淨等級，又稱無菌病房。</p>
                            <p>無菌層流病房是指通過層流淨化空調系統將進入室內的所有空氣按一定的速度朝同一方向流動時，病房內空氣流均為典型的單向流，防止塵粒及細菌在室內滯留及繁殖，通過層流淨化空調系統提供無菌環境，為免疫力極度低下患者提供治療、恢復的生物潔淨病房。</p>
                            <p>無菌病房的工作原理就是空氣通過高效率濾網(HEPA)的過濾，可以清除超過99.9%的大於0.3μm塵粒及細菌而使空氣得以淨化，使之達到基本無菌的程度，水平或垂直地在室內通過，創造出沒有灰塵粒子、沒有微生物的潔淨空間，以達到防止感染的目的。</p>

                            <br />

                            <h5 style={{ marginBottom: '18px', fontFamily: 'Noto Sans TC Medium' }}><b>CACis – 8P</b></h5>
                            <p className='mb-0'>參考【負壓病房】之理念設計</p>
                            <p>將病患吐出或室內空間內之汙染空氣吸入產品內，透過產品淨化後排出，可降低交叉感染之風險，也可提升室內空氣品質。</p>
                            <p>負壓隔離病房主要是利用病室內外負壓差使生物氣膠不致散播於室外，再利用足夠的換氣次數稀釋病室內的氣膠濃度，並設計適當的定向氣流使病患產生的生物氣膠不致流向醫護人員，從而確保醫護人員及其他病患的安全。</p>

                            <br />
                            <h5 style={{ marginBottom: '18px', fontFamily: 'Noto Sans TC Medium' }}><b>濾網設計</b></h5>
                            <p>參考【手術房過濾系統】【醫療單位、藥廠過濾箱】之設計理念，濾網與產品接合面採用凝膠式安裝，可通過洩漏測試。</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            <div className="fullscreen-bg">
                <img src="/product/product1.jpg" alt="全屏背景圖片" />
                <div className="overlay"></div>
                <div className="overlay-text-leftTop">
                    <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}>提升室內的空氣品質</h4>
                    <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}>降低環境對於健康的威脅</h4>
                </div> 
                <div className="overlay-text-rightBottom">
                    <p className="text-shadow" style={{ fontFamily: 'Noto Sans TC' }}>現代人於市內的生活與工作時間，常常佔了超過一天的三分之二，室內的空氣品質，將直接密切的影響著生活品質、身心健康，對於良好空氣品質的追求，必須好好的重視。空氣作為許多病毒的傳染途徑、也是常常包含著看不見的過敏原與許多細懸浮微粒，不但有可能可以穿透一般口罩，也因此會提高各種過敏反應、肺部疾病等風險。</p>
                </div>
                <div className="video-overlay-text2">
                    <div>
                        <Link to="/contact" className='mx-1'><button className="transparent-button">立即訂購</button></Link>
                        {/*<img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />*/}
                    </div>
                </div>
            </div>

            <div className="text-center-container" style={{ backgroundColor: '#ECECEB' }}>
                <h4 className="mobileFontSize" style={{ fontFamily: 'Noto Sans TC Medium' }}>最高規格的清淨效果，降低環境對於健康的威脅</h4>
            </div>
            {/** <div className="bg-image"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/product/product7.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '75vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            />*/}
            <img src="/product/product7.png" style={{ width: "100%", height: 'auto' }} />
            <div style={{ padding: '30px 15% 50px 16%', backgroundColor: '#ECECEB' }}>
                <p style={{ fontFamily: 'Noto Sans TC Regular' }}><span style={{ fontFamily: 'Exo VariableFont wght', fontWeight: '550' }}>spaceair+</span> 是市面上通過國家醫療器材級檢測的空氣淨化機，產品設計的初衷就是讓大家意識到要用最高規格的清淨效果，來降低環境對於健康的威脅。</p>
                <p style={{ fontFamily: 'Noto Sans TC Regular' }}>室內「吸入型過敏原」與「空氣汙染物」的過度增加，對免疫力較差的過敏病人會造成更大傷害；研究顯示，當懸浮微粒濃度每增加100μg/m3時，過敏氣喘病童會增加1.39倍的臨床呼吸道症狀。</p>
                <p style={{ fontFamily: 'Noto Sans TC Regular' }}>著名的美國心臟醫學會(<span className='english_FontFamily'>American Heart Association, AHA</span>)曾經發表聲明，表示空氣中的懸浮顆粒(<span className='english_FontFamily'>particular matter, PM</span>)會增加心血管疾病的風險及死亡率，短期暴露在<span className='english_FontFamily'>PM2.5</span>的環境中數小時到數星期，可能就會誘發心血管疾病的發生。</p>
            </div>

            <Container className="product01" style={{ backgroundColor: '#ECECEB' }}>
                <Row>
                    <Col md={2}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>符合 <span className='english_FontFamily'>EN1822</span> 規範濾網</h6>
                        <h6 style={{ fontFamily: 'Noto Sans TC Bold' }}>過濾一切健康危害因子</h6>
                    </Col>
                    <Col md={6}>
                        <img src="/product/product12.png" alt="產品圖片" style={{ width: '100%' }} />
                    </Col>
                    <Col md={4}>
                        <p style={{ fontFamily: 'Noto Sans TC Regular' }}>藉由玻璃纖維混合制菌獨特配方，過濾{'>'}<span className='english_FontFamily'>0.1-0.2μm</span>顆粒，能有效抑制細菌增生，避免濾網成為細菌溫床。除此之外，<span className='english_FontFamily'>spaceair+</span>還可以根據需求，進行濾網的配置調整。</p><br />
                        <p className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}>PTFE 濾網 效率</p>
                        <p style={{ fontFamily: 'Noto Sans TC Regular' }}>≥ <span className='english_FontFamily'>99.99995% @ 0.1μm (ULPA </span>等級)目的：過濾{'>'}0.1μm顆粒，高捕集效率，低壓損，耐化學性，使用超細微之纖維所結合而成的氟樹脂。</p><br />
                        <p className="mb-1" style={{ fontFamily: 'Noto Sans TC Bold' }}>二合一初級濾網 效率</p>
                        <p style={{ fontFamily: 'Noto Sans TC Regular' }}>比色法效<span className='english_FontFamily'>30-35%(ASHRAE 52.2 </span>為效率 <span className='english_FontFamily'>MERV8</span>)，過濾大粒子之灰塵毛髮，表面添加抗蝕性的金屬支撐網，摺型結構可增加過濾面並降低風阻，添加活性碳加強吸附空氣中有害化學汙染物(甲醛...等)及減少空氣中的異味、臭味以及有害人體的各種味道。</p>
                        <Link to="/contact"><button className="transparent-button2" style={{ marginTop: '12px', width: '100%' }}>立即訂購</button></Link>
                        {/*
                                <div className='d-flex justify-content-center' style={{ marginTop: '40px' }}>
                                <Dropdown>
                                <Dropdown.Toggle className="transparent-button3">
                                    產品說明書 (操作手冊)
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href={`${process.env.PUBLIC_URL}/product/CACis-8D.pdf`} target="_blank">CACis-8D.pdf</Dropdown.Item>
                                    <Dropdown.Item href={`${process.env.PUBLIC_URL}/product/CACis-8P.pdf`} target="_blank">CACis-8P.pdf</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                                </div>
                            */}

                        {/** <Link to="/contact"><button className="transparent-button3">產品說明書</button></Link>*/}
                    </Col>
                </Row>
            </Container>

            <div class="text-center-container">
                <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>UV-C 紫外線殺菌裝置，有快速、徹底、無汙染的殺菌</h4>
            </div>

            <div className="Product2">
                <Container>
                    <Row style={{ padding: '40px 0' }}>
                        <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                            <h5 style={{ fontFamily: 'Noto Sans TC Medium', fontWeight: '700' }}><span className='english_FontFamily2' >UV-C</span> ( 波長<span className='english_FontFamily2'> 253.7nm </span> )</h5>
                            <p style={{ margin: '0px' }}>對於危害人體的細菌、病毒、微生物...等，有極大的摧毀作用。</p>
                            <p style={{ margin: '0px' }}>一般經 UV-C 照射 1~2秒鐘即可達到滅菌的效果。</p>
                            <br />
                            <p>其殺菌原理是經 UV-C 照射細菌、病毒...等單細胞微生物，直接破壞其生命中樞 DNA (去氧核醣核酸)及 RNA (核醣核酸) 的結構，使得構成該微生物體的蛋白質無法形成，使其立即死亡或喪失繁殖能力。</p>
                            <br />
                            <h5 style={{ fontFamily: 'Noto Sans TC Medium', fontWeight: '700' }}>最大排風量達 <span className='english_FontFamily2' >15m3/min</span></h5>
                            <p style={{ margin: '0px' }}>遠大於一般市售空氣清淨機最大送風量(約 6-8 m3/min)，大幅提升空氣淨化功能。可以在醫生與病患之間呈現穩定氣流，形成空氣屏障保護。</p>
                            <p style={{ textAlign: 'right' }}>-臺北科技大學潔淨技術研發中心</p>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
                            <img className="mb-3" src="/product/product10.png" alt="產品圖片" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div class="text-center-container">
                <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>形成具有屏障的空氣流場</h4>
            </div>

            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/product/keelungair.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"></div>
                <div className="video-overlay-text7">
                    <p className="text-shadow" style={{ fontSize: '16px', fontWeight: 'bold' }}>spaceair+ 使用符合 <span className='english_FontFamily'>EN1822</span> 規範濾網，濾網效率： ≥ <span className='english_FontFamily'>99.99995%</span>，能過濾 >0.1μm顆粒，並有高捕集效率，低壓損，耐化學性的特性。有效過濾包含<span className='english_FontFamily'>PM2.5</span>、粉塵、花粉、異味、甲醛等化學汙染、細菌、過敏原等。同時也具備比市面上家用空氣清淨機高三倍的排風量 15m3/min，大幅提升空氣替換效率。</p>
                </div>
                <div className="video-overlay-text6">
                    <div style={{ padding: '5%' }}>
                        <h5 className="mb-4" style={{ textAlign: "center", fontFamily: 'Noto Sans TC Bold', fontSize: '24px' }}>抗菌淨化程度</h5>
                        <h2 ref={targetElementRef} style={{ textAlign: "center", fontFamily: 'Orbitron VariableFont wght', fontSize: '42px', fontWeight: '700' }}>{value.toFixed(1)}%</h2>
                    </div>
                    <div style={{ padding: '5%' }}>
                        <h5 className="mb-4" style={{ textAlign: "center", fontFamily: 'Noto Sans TC Bold', fontSize: '24px' }}>抗病毒淨化程度</h5>
                        <h2 style={{ textAlign: "center", fontFamily: 'Orbitron VariableFont wght', fontSize: '42px', fontWeight: '700' }}>{value.toFixed(1)}%</h2>
                    </div>
                    {/* <div style={{ padding: '5%' }}>
                        <h5 className="mb-4" style={{ textAlign: "center", fontFamily: 'Noto Sans TC Bold', fontSize: '24px' }}>空氣替換速度</h5>
                        <h2 style={{ textAlign: "center", fontFamily: 'Orbitron VariableFont wght', fontSize: '42px', fontWeight: '700' }}>{value.toFixed(1)}%</h2>
                    </div>*/}
                </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>
                <h4 style={{ padding: '100px 0 25px 0', textAlign: 'center', fontFamily: 'Noto Sans TC Medium' }}>多項國際專業品質認證</h4>
                <p style={{ textAlign: 'center', fontFamily: 'Noto Sans TC Medium' }}>如想了解更多專業執照、認證與測試文件內容，歡迎透過右下方按鈕，加入 LINE@ 與我們索取！</p>
                <div className="certificate">
                    <Container style={{ padding: '0 0 100px 0' }}>
                        <Row>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>製造業藥商許可執照</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-14.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/(最新版)廣隆-醫療許可相關-製造業藥商許可執照.JPG`} target="_blank" style={{ color: 'inherit', display: 'block', pointerEvents: 'none' }}>廣隆欣業 - 醫療許可執照</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/(最新版)廣隆-醫療許可相關-QMS證書.pdf`} target="_blank" style={{ color: 'inherit', display: 'block', pointerEvents: 'none' }}>廣隆欣業 - 衛生福利部醫療器材許可證</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/(最新版)廣隆-醫療許可相關-醫療器材許可證.pdf`} target="_blank" style={{ color: 'inherit', display: 'block', pointerEvents: 'none' }}>廣隆欣業 - 醫療器材製造許可證明書</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/恩典科研-醫療許可相關-販賣業藥商許可執照.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>恩典科研 - 販賣業藥商許可執照</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>認證文件</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-15.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/@20230818-spaceair+.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>spaceair+認證文件 @20230818</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>品質系統 - CE 證書</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-16.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }} href={`${process.env.PUBLIC_URL}/certification/廣隆-品質系統-CE證書-防疫屏風.pdf`} target="_blank">廣隆欣業 - CACis 系列證書</a>
                                    <a style={{ color: 'inherit', pointerEvents: 'none' }} href={`${process.env.PUBLIC_URL}/certification/廣隆-品質系統-CE證書-濾網FILTER-(3X200908.ACDCN04) EN1822.pdf`} target="_blank">廣隆欣業 - 濾網 FILTER ********</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>品質系統 - ISO</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-17.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-品質系統-ISO-9001.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 9001</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-品質系統-ISO13485-2016-英文版.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 13485 -2016 - 英文版</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>測試報告 - 落菌測試</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-18.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-落菌測試-中文版.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 落菌測試 - 中文版</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-落菌測試-英文版.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 落菌測試 - 英文版</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>測試報告 - 落塵測試</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-19.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-落塵測試-中文版.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 落塵測試 - 中文版</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-落塵測試-英文版.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 落塵測試 - 英文版</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>測試報告 - 流場可視化研究</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-22.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-空氣清淨機流場可視化研究case12-Final.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 空氣清淨機流場可視化研究</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>循環空氣清淨設備專利證明</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-20.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-專利-循環空氣清淨設備-中華民國.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 專利 - 循環空氣清淨設備 - 中華民國</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-專利-循環空氣清淨設備-日本.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 專利 - 循環空氣清淨設備 - 日本</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-專利-循環空氣清淨設備-德國.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 專利 - 循環空氣清淨設備 - 德國</a>
                                </div>
                            </Col>
                            <Col className="certificate-panel" md={4} style={{ border: '1px solid black' }}>
                                <div className="certificate" style={{ padding: '20px' }}>
                                    <h5 className='my-2'>測試報告 - 紫外線 UV 殺菌系統</h5>
                                    <div style={{ margin: '24px 0 36px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <img src='/product/icon-21.png' style={{ width: '60px' }} />
                                            <img src='/product/貼紙.png' style={{ width: '60px', margin: '0 12px' }} />
                                        </div>
                                        <img src='/global/plus.png' style={{ width: '18px', height: '18px', textAlign: 'right' }} />
                                    </div>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-UV燈-M61-221000927-“廣隆欣業”醫用再循環紫外線空氣淨化機(10W)紫外線UV 殺菌系統.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 醫用再循環紫外線空氣淨化機 *****</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-UV燈-M61-221000928-“廣隆欣業”醫用再循環紫外線空氣淨化機(10W)紫外線UV 殺菌系統.pdf`} target="_blank" style={{ display: 'block', color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 醫用再循環紫外線空氣淨化機 *****</a>
                                    <a href={`${process.env.PUBLIC_URL}/certification/廣隆-測試報告-UV燈-M61-221000929-“廣隆欣業”醫用再循環紫外線空氣淨化機(10W)紫外線UV 殺菌系統.pdf`} target="_blank" style={{ color: 'inherit', pointerEvents: 'none' }}>廣隆欣業 - 醫用再循環紫外線空氣淨化機 *****</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="fullscreen-bg">
                <img src="/product/product6.jpg" alt="全屏背景圖片" />
                <div className="overlay"></div>
                <div className="overlay-text-leftBottom2_product">
                    <h5 style={{ fontFamily: 'Noto Sans TC Medium' }}>堅守價值，達成永續目標</h5>
                    <br /><br />
                    <h6 style={{ fontFamily: 'Noto Sans TC Medium' }}>專注於提升空氣品質</h6>
                    <h6 style={{ fontFamily: 'Noto Sans TC Medium' }}>持續創造讓人感到安全的、健康的生活與工作環境</h6>
                </div>
                <div className="overlay-text-rightTop_product">
                    <h5 className='mb-5' style={{ fontFamily: 'Noto Sans TC Medium' }}><img src='/global/plus.png' style={{ width: '22px', verticalAlign: 'text-top', marginRight: '5px' }} />醫材等級空氣清淨裝備</h5>
                    <h5 className='mb-5' style={{ fontFamily: 'Noto Sans TC Medium' }}><img src='/global/plus.png' style={{ width: '22px', verticalAlign: 'text-top', marginRight: '5px' }} />台灣頂級科技大學研究</h5>
                    <h5 style={{ fontFamily: 'Noto Sans TC Medium' }}><img src='/global/plus.png' style={{ width: '22px', verticalAlign: 'text-top', marginRight: '5px' }} />大型醫療院所證明</h5>
                    <h5 style={{ marginLeft: '27px', fontFamily: 'Noto Sans TC Medium' }}>有效降低空氣感染率</h5>
                </div>
                <div className="video-overlay-text2">
                    <Link to="contact"><button className="transparent-button">立即訂購</button></Link><br />
                </div>
            </div>
        </div >
    )
}

export default Product;