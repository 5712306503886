import React, { useEffect } from 'react';
import '../App.css';
import '../fonts.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function Home() {

    useEffect(() => {
        // 在頁面渲染完成後，將滾動位置設置為最上方
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/mainpage/main01.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"></div>
                <div className="video-overlay-text1">
                    <img src='/global/spaceair.png' alt='space air+' style={{ width: '320px', height: 'auto', display: 'block' }} />
                </div>
                <div className="video-overlay-text1">
                    <h3 style={{ fontFamily: 'Noto Sans TC Medium', marginTop: '200px' }}>醫療級空氣淨化機</h3>
                </div>
                <div className="video-overlay-text2">
                    <div>
                        <Link to="contact"><button className="transparent-button">立即訂購</button></Link><br />
                        {/* <img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />
                        <br />*/}
                        <img src='/mainpage/img02.png' alt='醫用等級空氣淨化機' style={{ width: '300px', height: 'auto' }} /><br /><br />
                        <p style={{ marginBottom: '0px', fontFamily: 'Noto Sans TC Regular', color: 'white', fontSize: '12px' }}>【廣告許可證字號】中市衛器廣字第11305027號</p>
                    </div>
                </div>
            </div>
            <Container className='Home1' fluid="md">
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px', textAlign: "center" }} /></div>
                <Row>
                    <Col xs={12} sm={6}>
                        <img src='/mainpage/dm01.jpg' alt='dm1' style={{ width: '100%' }} />
                        <br />
                    </Col>
                    <Col xs={12} sm={6}>
                        <img src='/mainpage/dm02.jpg' alt='dm2' style={{ width: '100%' }} />
                        <br />
                    </Col>
                </Row>
            </Container>
            <Container className='Home1' fluid="md">
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px', textAlign: "center" }} /></div>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>醫院與診所</h4>
                        <img src='/mainpage/hospital.png' alt='醫院與診所' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular' }}>提升進行醫療工作的安全性，降低工作環境的空氣粒子感染風險，確保患者能更安心地接受專業治療</p>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>飯店與高級住宅</h4>
                        <img src='/mainpage/privatehome.png' alt='高級營業場所與私人俱樂部' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular' }}>創造生活中醫用級的空氣品質，遠離懸浮粒子，享受健康、無負擔的生活狀態</p>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>公司與辦公室</h4>
                        <img src='/mainpage/office.png' alt='公司與辦公室' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular' }}>提供讓人安心的工作環境，照顧員工對於空氣品質的健康需求，激發員工更好的工作狀態與效率</p>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>高級營業場所與私人俱樂部</h4>
                        <img src='/mainpage/privatehome.png' alt='高級營業場所與私人俱樂部' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular' }}>提供客戶高級的環境場域，除去異味、淨化空氣，享受尊榮的服務體驗</p>
                        <br />
                    </Col>
                </Row>
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px' }} /></div>
            </Container>
            {/** <div className="container1">
                <div className="item">

                </div>
                <div className="item">

                </div>
                <div className="item">

                </div>
                <div className="item">

                </div>
            </div>*/}
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/mainpage/main02.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay"></div>
                <div className="video-overlay-text3">
                    <img src='/mainpage/img03.png' alt='' style={{ width: '350px', height: 'auto' }} />
                </div>
                <div className="video-overlay-text5">
                    <p className="text-shadow" style={{ fontFamily: 'Noto Sans TC Thin'}}>近年來，藉由空氣傳染的呼吸道傳染病週期性的在全球引起許多健康危機。2020 Covid-19 疫情的爆發，更是讓空氣品質的議題一躍成為每個人所關心的健康話題，尤其是這個世代，我們有超過三分之一的時間都在於室內活動與人交流，如何有效提升我們生活空間的空氣品質，有效降低染疫的風險提升生活的安全性，儼然成為一個評估生活品質的重要指標。</p>
                </div>
                <div className="video-overlay-text2">
                    <div>
                        <Link to="product"><button className="transparent-button">了解更多</button></Link><br />
                        {/*<img src='/global/learnmore.png' alt='了解更多' style={{ width: '300px', height: 'auto' }} />*/}
                    </div>
                </div>
            </div>
            <div className="fullscreen-bg">
                <img src="/mainpage/img04.png" alt="全屏背景圖片" />
                <div className="video-overlay3"></div>
                <div className="video-overlay-text4">
                    <h4 style={{ fontFamily: 'Noto Sans TC Medium' }}>擁有醫材證的空氣淨化機</h4>
                    <p className='mt-3'>是目前市面上第一台有醫材品質的空氣淨化機，擁有最高規格 ULPA 濾網，能夠過濾空氣中 ≧ 99.99995% @ 0.1um 的空氣汙染微粒、病菌，同時也具備了 UV-C 紫外線殺菌，細菌、病毒、寄生蟲、病原體和藻類...等均可有效殺滅，而且不會造成二次汙染。</p>
                    <br /><br />
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        高端濾網與高強度的紫外線殺菌功能
                    </p>
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        過濾空氣中 ≧ 99.99995% @ 0.1um 的有害微粒
                    </p>
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        設計單向空氣流場，形成空氣屏障
                    </p>
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        市面上第一台醫用級空氣淨化機
                    </p>
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        使用對於環境友善、節能導向的產品設計
                    </p>
                    <p>
                        <img src='/global/plus.png' alt='plus' style={{ width: '18px', padding: '2px 0 6px 0', marginRight: '5px' }} />
                        符合 EN1822 規範濾網 UV-C 紫外線殺菌
                    </p><br />
                    <Link to="contact"><button className="transparent-button">立即訂購</button></Link>
                </div>
                {/*<div className="video-overlay-text2">
                    <div>
                        <img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />
                    </div>
                </div>*/}
            </div>
            <Container className='Home1' fluid="md">
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px', textAlign: "center" }} /></div>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://today.line.me/tw/v2/article/eL9Y03B' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/06/25</h6>
                        <img src='/news/n1.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>第6波新冠來襲…急診室百人等病床　台大醫院宣布：恢復疫情時管控措施</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://tw.news.yahoo.com/%E6%96%B0%E5%86%A0%E5%8F%88%E4%BE%86%E4%BA%86-%E5%89%8D%E5%8F%B0%E5%A4%A7%E9%86%AB%E5%88%86%E6%9E%90-2%E7%A8%AE%E4%BA%BA-%E7%97%87%E7%8B%80%E5%81%8F%E5%9A%B4%E9%87%8D-004020849.html' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/06/18</h6>
                        <img src='/news/n2.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>新冠又來了！前台大醫分析「2種人」症狀偏嚴重</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://ctinews.com/news/items/2BnELloexJ' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/31</h6>
                        <img src='/news/n3.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>影/PM2.5恐怖真相曝光 心臟名醫林俊忠神比喻就像小小火星 一旦點燃足以燎原</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://www.mohw.gov.tw/cp-16-79055-1.html' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/06/17</h6>
                        <img src='/news/n4.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>112年國人死因統計結果</p>
                        </a>
                        <br />
                    </Col>

                    <Col xs={12} sm={6} md={3}>
                        <a href='https://udn.com/news/story/123550/8035497' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/06/18</h6>
                        <img src='/news/n5.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>衛福部公布112十大死因 癌症居冠不意外 新冠排名受矚目</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://www.chinatimes.com/realtimenews/20240618002873-260405?chdtvn6.jpeg' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/06/18</h6>
                        <img src='/news/n6.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>新冠重症及死亡幾乎都翻倍！單周增623例、38死</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://today.line.me/tw/v2/article/Op9YKVE?utm_source=lineshare' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/27</h6>
                        <img src='/news/n7.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>3大病毒夾擊！全台「進入流行高峰期」　公衛專家急籲這件事</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://ynews.page.link/wDzqT' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/19</h6>
                        <img src='/news/n8.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>好多人發燒狂咳！ 醫曝「病毒前三名」喊：一定要來快篩</p>
                        </a>
                        <br />
                    </Col>

                    <Col xs={12} sm={6} md={3}>
                        <a href='https://udn.com/news/story/7266/7961804' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/14</h6>
                        <img src='/news/n9.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>空汙要命／環境部修空品標準 被批太鬆</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://udn.com/news/story/7266/7961808' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/14</h6>
                        <img src='/news/n10.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>國衛院10年追蹤研究 空汙增中風死亡率</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://udn.com/news/story/7266/7961707' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/14</h6>
                        <img src='/news/n11.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>新聞眼／空汙嚴重地區 應加強癌篩</p>
                        </a>
                        <br />
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <a href='https://udn.com/news/story/7266/7944424' target='_blank' style={{ color:'#757679', textDecoration: 'none' }}>
                        <h6 style={{ fontFamily: 'Noto Sans TC Medium'}}>📰 2024/05/06</h6>
                        <img src='/news/n12.jpeg' style={{ width: '100%' }} />
                        <p className='pt-3' style={{ fontFamily: 'Noto Sans TC Regular', textDecoration: 'underline'  }}>台大研究發現 空汙罹肺癌風險高於吸菸</p>
                        </a>
                        <br />
                    </Col>
    
                </Row>
                <div className="showOnMobile"><img src='/global/plus.png' alt='plus' style={{ width: '24px', padding: '2px 0 6px 0', marginRight: '5px' }} /></div>
            </Container>
            <div className='fullscreen-video'>
                <video autoPlay muted loop playsInline>
                    <source src="/mainpage/MVI_0021.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="video-overlay" style={{ backdropFilter: 'blur(5px)' }}></div>
                <div className="video-overlay-text3 mb-3">
                    <div style={{ marginBottom: '42px' }}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}><img src='/global/plus.png' alt='plus' style={{ width: '22px', padding: '2px 0 6px 0', marginRight: '15px' }} />安全的社群互動</h4>
                        <p style={{ marginLeft: '37px', fontWeight: 'bold' }}>有效淨化生活中的空氣，降低汙染微粒的存在，讓人與人的互動更加安心。</p>
                    </div>
                    <div style={{ marginBottom: '42px' }}>
                        <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}><img src='/global/plus.png' alt='plus' style={{ width: '22px', padding: '2px 0 6px 0', marginRight: '15px' }} />安心的工作環境</h4>
                        <p style={{ marginLeft: '37px', fontWeight: 'bold' }}>創造更好的工作環境，如：醫院、診所、辦公室...等，提升員工的身心健康水平與工作效率。</p>
                    </div>
                    <div>
                        <h4 style={{ fontFamily: 'Noto Sans TC Bold' }}><img src='/global/plus.png' alt='plus' style={{ width: '22px', padding: '2px 0 6px 0', marginRight: '15px' }} />更好的生活品質</h4>
                        <p style={{ marginLeft: '37px', fontWeight: 'bold' }}>降低高品質生活場景中，如：飯店、俱樂部、住宅...等，藉由空氣傳播而受到感染的機率。</p>
                    </div>
                </div>

                <div className="video-overlay-text2">
                    <div>
                        <Link to="contact"><button className="transparent-button">立即訂購</button></Link>
                        {/* <img src='/global/clickToBuy.png' alt='立即訂購' style={{ width: '300px', height: 'auto' }} />*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;